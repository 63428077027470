import { useContext, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./FilterBar.scss";
// import filtericon from '../../../assets/icons/filter.svg';
import { ModalContext } from "../../context/ModalContext";
import { AppStore } from "../../../../redux/store";

import useCallApiAndLoad from "../../../../hooks/useCallApiAndLoad";
import { fetchResponseAdapter } from "../../../../adapters/fetchAdapter";
import { setFiltersAction } from "../../../../redux/features/filters";
import React from "react";
import { MainModalContext } from "../../../../context/MainModalContext";
import { searchData } from "../../../../services/publicService";
import { FiltersStore } from "../../../../models/PropertyModel";
import {
  cleanFormParams,
  replaceFormParams,
} from "../../helpers/paramsHelpers";
import { getTypePropertiesEndpoint } from "../../../Properties/services/PropertiesService";
import { createSearchParams, useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Fade, Snackbar } from "@mui/material";

import {
  IconBuilding,
  IconBuildingCommunity,
  IconBuildingSkyscraper,
  IconFilter,
  IconLink,
  IconMapPin,
  IconMapPins,
  IconX,
} from "@tabler/icons-react";
import SelectMaxMinPrice from "./components/SelectMaxMinPrice/SelectMaxMinPrice";
import Skeleton from "react-loading-skeleton";

interface PublishCatalogs {
  propertyTypes: Array<any>;
}

interface FilterProps{
  showFilter: boolean;
}

const FilterBar = ({showFilter}: FilterProps ) => {
  const { country } = useSelector((state: AppStore) => state.country);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(0);
  const [searchText, setSearchText] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [notResults, setNotResults] = useState(false);
  const [openMessage, setOpenMessage] = React.useState(false);

  const navigate = useNavigate();

  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  let { handleModal } = useContext(ModalContext);
  let { handleMainModal } = useContext(MainModalContext);
  const { callEndpoint } = useCallApiAndLoad();
  const [catalogs, setCatalogs] = useState<PublishCatalogs>({
    propertyTypes: [],
  });
  const filters = useSelector((store: AppStore) => store.filters);
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [selectedOptionSearch, setSelectedOptionSearch] = useState<
    string | null
  >(filters?.location_id !== null ? window.localStorage.getItem("city") : null);
  const [dataSearchHits, setDataSearchHits] = useState<any>([]);

  useEffect(() => {
    const callCatalogs = async () => {
      const propertyTypes = await callEndpoint(getTypePropertiesEndpoint());
      if (propertyTypes.status !== 200) {
        return;
      }
      const propertyTypesResponse = fetchResponseAdapter(propertyTypes.data);
      setCatalogs({
        propertyTypes: propertyTypesResponse.data,
      });
    };
    callCatalogs().catch((e) => console.error(e));
  }, []);

  const removeFilter = (filterName: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete(filterName);

    dispatch(setFiltersAction({ [filterName]: null }));
    window.history.replaceState(
      null,
      "",
      `${window.location.pathname}?${urlParams}`
    );
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const value = event.target.value;

    setSelectedOptionSearch(null);
    if (event.target.value.length > 2) {
      setSearchText(value);
      seachDataElastic(event.target.value);
    } else {
      setDataSearchHits([]);
      setShowSuggestions(false);
      setNotResults(false);
    }

    if (dataSearchHits.length > 0) {
      setHighlightedIndex(0);
    } else {
      setHighlightedIndex(-1);
    }

    // setShowSuggestions(value.trim().length > 0);
    // searchElasticsearch(event.target.value)
    // seachDataElastic(event.target.value)
  };

  const handleSuggestionClick = (
    suggestion: string,
    id: string,
    type: string,
    long: number,
    lati: number,
    zoomMap: number
  ) => {
    setSearchText(suggestion);
    setSelectedOptionSearch(suggestion as string);
    submitFilter(id, type, long, lati, zoomMap);
    window.localStorage.setItem("city", suggestion as string);
    setDataSearchHits([]);
  };

  const seachDataElastic = async (query: string) => {
    // const queryData = {
    //   query: {
    //     match: {
    //       value: {
    //         query: query,
    //       }, // Reemplaza "campo" con el nombre del campo que deseas buscar en ElasticSearch
    //     },
    //   },
    //   sort: [
    //     {
    //       search_rank: {
    //         order: "desc",
    //       },
    //     },
    //   ],
    // };
    const queryData = {
      _source: [],
      size: 25,
      min_score: 0,
      query: {
        bool: {
          must: [
            {
              match_phrase_prefix: {
                value: {
                  // query: "zona 15*",
                  query: `${query}*`,
                },
              },
            },
          ],
          filter: [],
          should: [],
          must_not: [],
        },
      },
      sort: [
        {
          level: {
            order: "asc",
          },
        },
      ],
    };
    const data = await callEndpoint(searchData(queryData));
    if (data?.status !== 200) {
      setShowSuggestions(true);
    }

    const filteredSuggestions = data?.data?.hits?.hits?.map(
      (hit: any) => hit._source
    );

    if (filteredSuggestions?.length === 0) {
      setNotResults(true);
    } else {
      setNotResults(false);
    }

    setDataSearchHits(filteredSuggestions);
  };

  const submitFilter = (
    idCity: string,
    typeCity: string,
    lng: number,
    lat: number,
    zoom: number
  ) => {
    const data = {
      location_id: idCity,
      location_type: typeCity,
      lat: lat || -89.18718,
      lng: lng || 13.68935,
      zoom_search: zoom || 10,
    };
    const replaceParamsObject: any = {
      ...filters,
      ...data,
    };
    if (JSON.stringify(data) !== "{}") {
      const filterDataMapped: FiltersStore =
        replaceFormParams(replaceParamsObject);
      dispatch(setFiltersAction(filterDataMapped));
      const cleanedFilters = cleanFormParams(filterDataMapped);
      if (Object.keys(cleanedFilters).length > 0) {
        navigate(`?${createSearchParams(cleanedFilters)}`);
      }
    }
  };

  console.log({ highlightedIndex });

  const suggestionList = dataSearchHits?.length > 0 && (
    <ul className="suggestion-list hidden lg:block">
      {dataSearchHits.map((item: any, index: number) => (
        <li
          key={item.id}
          className={`suggestion flex justify-start items-center py-3 ${
            highlightedIndex === index ? "selected" : ""
          }`}
          onMouseDown={(e) => e.preventDefault()}
          onClick={() =>
            handleSuggestionClick(
              item.value,
              item.id,
              item.type,
              item?.location?.coordinates[0],
              item?.location?.coordinates[1],
              item?.location?.zoom
            )
          }
          data-value={item.value}
        >
          {item.type === "city" && (
            <IconBuildingSkyscraper className="mr-2" color="#757575" />
          )}
          {item.type === "state" && (
            <IconMapPin className="mr-2" color="#757575" />
          )}
          {item.type === "district" && (
            <IconMapPins className="mr-2" color="#757575" />
          )}
          {item.type === "nb" && (
            <IconBuildingCommunity className="mr-2" color="#757575" />
          )}
          {item.type === "tower" && (
            <IconBuilding className="mr-2" color="#757575" />
          )}
          <p className="text-sm font-medium text-[#757575]">{item.value}</p>
        </li>
      ))}
    </ul>
  );

  const serviceError = showSuggestions && (
    <ul className="suggestion-list hidden lg:block">
      <p className="py-3 text-sm font-normal text-graySoft text-center">
        Se ha presentado un problema, intentelo más tarde
      </p>
    </ul>
  );

  const notResult = notResults && (
    <ul className="suggestion-list hidden lg:block">
      <p className="py-3 text-sm font-normal text-graySoft text-center">
        Lo sentimos, no hay resultados
      </p>
    </ul>
  );

  const handleInputBlur = () => {
    setSearchText("");
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // New logic individual

  const [openOption, setOpenOption] = useState<null | HTMLElement>(null);
  const open = Boolean(openOption);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenOption(event.currentTarget);
  };
  const handleClose = () => {
    setOpenOption(null);
  };

  const [openOption2, setOpenOption2] = useState<null | HTMLElement>(null);
  const open2 = Boolean(openOption2);
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenOption2(event.currentTarget);
  };
  const handleClose2 = () => {
    setOpenOption2(null);
  };

  const [marketSelected, setMarketSelected] = useState<string>("on_rent");

  const [typeSelected, setType] = useState<string>("");

  const [showPrice, setShowPrice] = useState(false);
  const openPriceFilter = () => {
    setShowPrice(!showPrice);
  };

  // ASIGNAR NUEVOS FILTROS INDIVIDUALES

  const onChangeMarketType = (type: string) => {
    setMarketSelected(type);
    onHandleChangeTypeMarket(type);
  };

  const onChangePropertyType = (type: string) => {
    setType(type);
    onHandleChangeTypeProperty(type);
    handleClose2();
  };

  const onHandleChangeTypeMarket = (type: string) => {
    const nullableData = {
      ...filters,
      marketplace_type: type || null,
    };
    const dataFilter: FiltersStore = replaceFormParams(nullableData);

    const cleanedFilters = cleanFormParams(dataFilter);
    dispatch(setFiltersAction(cleanedFilters));
    if (Object.keys(cleanedFilters).length > 0) {
      navigate(`?${createSearchParams(cleanedFilters)}`);
    }
    handleClose();
  };

  const onHandleChangeTypeProperty = (type: string) => {
    const nullableData = {
      ...filters,
      type: type || null,
    };
    const dataFilter: FiltersStore = replaceFormParams(nullableData);

    const cleanedFilters = cleanFormParams(dataFilter);
    dispatch(setFiltersAction(cleanedFilters));
    if (Object.keys(cleanedFilters).length > 0) {
      navigate(`?${createSearchParams(cleanedFilters)}`);
    }
    handleClose();
  };

  useEffect(() => {
    const cleanedFilters = cleanFormParams(filters);
    setMarketSelected(cleanedFilters?.marketplace_type);
    setType(cleanedFilters?.type);
  }, [filters]);

  const showPropertyType = (typeSelected: string) => {
    switch (typeSelected) {
      case "house":
        return "Casa";
      case "apto":
        return "Apartamento";
      case "land":
        return "Terreno";
      default:
        return "Tipo de propiedad";
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (dataSearchHits.length > 0) {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex === dataSearchHits.length - 1 ? 0 : prevIndex + 1
        );
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex === 0 ? dataSearchHits.length - 1 : prevIndex - 1
        );
      } else if (event.key === "Enter") {
        event.preventDefault();
        if (dataSearchHits[highlightedIndex]) {
          const item = dataSearchHits[highlightedIndex];
          handleSuggestionClick(
            item.value,
            item.id,
            item.type,
            item?.location?.coordinates[0],
            item?.location?.coordinates[1],
            item?.location?.zoom
          );
        }
      }
    }
  };

  const generateShareableURL = () => {
    if (typeof window === "undefined") return "";
  
    const currentUrl = new URL(window.location.href);
    const params = new URLSearchParams(currentUrl.search);
  
    // Obtener el valor de marketplace_type antes de eliminarlo
    const marketplaceType = params.get("marketplace_type");
  
    // Eliminar el parámetro que no queremos incluir en la nueva URL
    params.delete("marketplace_type");
  
    // Determinar la ruta según marketplace_type
    let path = "/comprar"; // Valor por defecto
    if (marketplaceType === "for_rent") {
      path = "/alquilar";
    } else if (marketplaceType === "for_sale") {
      path = "/comprar";
    }
  
    // Construir la nueva URL
    return `https://www.propilatam.com/sv/bienes-raices${path}?${params.toString()}`;
  };

  const ShareButton = () => {
    const handleShare = async () => {
      const url = generateShareableURL();
  
      try {
        await navigator.clipboard.writeText(url);
        setOpenMessage(true)
      } catch (error) {
        console.error("Error al copiar la URL:", error);
      }
    };
  
    return (
      <button className=" focus:outline-none px-2 font-medium py-1 bg-[#212121] rounded-lg flex justify-between items-center text-xs text-white" type="button" onClick={handleShare}>
        <IconLink width={14} height={14} className="mr-1" /> Copiar enlace
      </button>
    );
  };
  
  

  return (
    <>
    {
      showFilter ? <div className="max-w-[1440px] mx-auto px-6 xl:px-6">
        <div className="w-full flex flex-row justify-between">
          <div className="w-full flex flex-nowrap flex-row pt-4 pb-4 items-center md:px-4">
                <div className="relative w-full hidden lg:flex md:w-2/3 xl:w-1/3">
              <div className="w-full">
                <Skeleton count={1} height={50} borderRadius={10} className="w-full" />
              </div>
              </div>
              <div className="relative w-full flex lg:hidden md:w-2/3 xl:w-1/3">
              <div className="w-full">
                <Skeleton count={1} height={25} borderRadius={10} className="w-full" />
              </div>
              
              </div>
              <div className="h-8 bg-gray500 w-[0.5px] mx-3 hidden lg:block" />
              <div className="w-auto px-4 hidden lg:block">
                <div className="w-full">
                  <Skeleton count={1} height={50} width={132} borderRadius={10} className="w-full" />
                </div>
              </div>
              <div className="h-8 bg-gray500 w-[0.5px] mx-3 hidden xl:block" />
              <div className="w-auto px-4 hidden xl:block">
                <div className="w-full">
                  <Skeleton count={1} height={50} width={206} borderRadius={10} className="w-full" />
                </div>
              </div>
              <div className="h-8 bg-gray500 w-[0.5px] mx-3 hidden xl:block" />
              <div className="w-auto px-4 relative hidden xl:block">
                <div className="w-full">
                  <Skeleton count={1} height={50} width={191} borderRadius={10} className="w-full" />
                </div>
              </div>
              <div className="h-8 bg-gray500 w-[0.5px] mx-3 hidden xl:block" />
              <div className="w-auto px-4 relative hidden xl:block">
                <div className="w-full">
                  <Skeleton count={1} height={50} width={139} borderRadius={10} className="w-full" />
                </div>
              </div>
          </div>
        </div>
      </div> : 
      <>
      <div className="w-full bg-[#F5F5F5] relative z-10">
        <div className="max-w-[1440px] mx-auto px-6 xl:px-6">
          <div className="w-full flex flex-row justify-between">
            <form className="w-full ">
              <div className="flex flex-nowrap flex-row pt-4 pb-4 items-center md:px-4">
                <div className="relative w-full md:w-2/3 xl:w-1/3 flex">
                  {/* <input type="search" id="search-dropdown" className="block p-2.5 w-full z-20 text-sm text-white  rounded-r-lg focus:ring-blue-500 focus:border-blue-500 " placeholder="Buscar por ciudad..." /> */}
                  <div className="relative w-full">
                    <input
                      value={selectedOptionSearch as string}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      ref={inputRef}
                      placeholder="Buscar por zona o ciudad"
                      className="input-search hidden lg:block"
                      type="text"
                      onKeyDown={handleKeyDown}
                    />
                    <div
                      onClick={() => {
                        document.body.style.overflowY = "hidden";
                        handleMainModal();
                      }}
                      className="input-search block lg:hidden bg-white"
                    >
                      <p className="text-sm font-normal text-graySoft truncate">
                        {filters?.location_id === null ||
                        filters?.location_id === undefined
                          ? "Buscar por zona o ciudad"
                          : window.localStorage.getItem("city")}
                      </p>
                    </div>

                    {suggestionList}
                    {notResult}
                    {serviceError}
                  </div>
                </div>
                <div className="h-8 bg-gray500 w-[0.5px] mx-3 hidden lg:block" />
                {/* FILTRO TYPE MARKET  */}
                <div className="w-auto px-4 hidden lg:block">
                  <Button
                    id="basic-button"
                    aria-controls={open ? "fade-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    className="filter-link"
                  >
                    {marketSelected === "for_sale" ? "Comprar" : "Alquilar"}
                    <i className={`gg-chevron-down ml-4`}></i>
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={openOption}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    TransitionComponent={Fade}
                    className="float-option"
                  >
                    <MenuItem>
                      <div className="px-4 py-1">
                        <label className="customRadio text-sm text-graySoft">
                          Comprar
                          <input
                            type="radio"
                            value="for_sale"
                            name="selectCuota"
                            defaultChecked={marketSelected === "for_sale"}
                            onChange={(e) => onChangeMarketType(e.target.value)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </MenuItem>

                    <MenuItem>
                      <div className="px-4 py-1">
                        <label className="customRadio text-sm text-graySoft">
                          Alquilar
                          <input
                            type="radio"
                            value="for_rent"
                            name="selectCuota"
                            defaultChecked={marketSelected === "for_rent"}
                            onChange={(e) => onChangeMarketType(e.target.value)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </MenuItem>
                  </Menu>
                </div>
                <div className="h-8 bg-gray500 w-[0.5px] mx-3 hidden xl:block" />
                {/* FILTRO TYPE  */}
                <div className="w-auto px-4 hidden xl:block">
                  <Button
                    id="basic-button"
                    aria-controls={open2 ? "fade-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open2 ? "true" : undefined}
                    onClick={handleClick2}
                    className="filter-link"
                  >
                    {showPropertyType(typeSelected)}

                    <i className={`gg-chevron-down ml-4`}></i>
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={openOption2}
                    open={open2}
                    onClose={handleClose2}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    TransitionComponent={Fade}
                    className="float-option"
                  >
                    <MenuItem>
                      <div className="px-4 py-1">
                        <label className="customRadio text-sm text-graySoft">
                          Casa
                          <input
                            type="radio"
                            value="house"
                            name="selectCuota"
                            defaultChecked={typeSelected === "house"}
                            onChange={(e) =>
                              onChangePropertyType(e.target.value)
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </MenuItem>
                    <MenuItem>
                      <div className="px-4 py-1">
                        <label className="customRadio text-sm text-graySoft">
                          Apartamento
                          <input
                            type="radio"
                            value="apto"
                            name="selectCuota"
                            defaultChecked={typeSelected === "apto"}
                            onChange={(e) =>
                              onChangePropertyType(e.target.value)
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </MenuItem>
                    {marketSelected === "for_sale" && country === "sv" && (
                      <MenuItem>
                        <div className="px-4 py-1">
                          <label className="customRadio text-sm text-graySoft">
                            Terreno
                            <input
                              type="radio"
                              value="land"
                              name="selectCuota"
                              defaultChecked={typeSelected === "land"}
                              onChange={(e) =>
                                onChangePropertyType(e.target.value)
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </MenuItem>
                    )}
                  </Menu>
                </div>

                <div className="h-8 bg-gray500 w-[0.5px] mx-3 hidden xl:block" />

                {/* FILTRO RANGE PRICE  */}
                <div className="w-auto px-4 relative hidden xl:block">
                  <Button
                    id="basic-button"
                    aria-haspopup="true"
                    className="filter-link"
                    onClick={openPriceFilter}
                  >
                    {filters.marketplace_type !== undefined
                      ? "Rango de precio"
                      : filters?.marketplace_type === "for_rent"
                      ? "Renta mensual"
                      : "Precio total"}
                    <i className={`gg-chevron-down ml-4`}></i>
                  </Button>
                  {showPrice && <SelectMaxMinPrice onClose={openPriceFilter} />}
                </div>

                <div className="h-8 bg-gray500 w-[0.5px] mx-3 hidden lg:block" />
                {/* FILTRO MORE FILTERS  */}
                <div className="relative pl-4 lg:pl-0 md:mt-0 md:pt-0 text-center flex justify-start items-center lg:px-4">
                  {/* <button type="submit" className="hidden lg:flex relative top-0 right-0 py-2.5 px-4 text-sm font-medium text-white bg-secondary rounded-lg ">Buscar</button> */}
                  <div
                    onClick={() => {
                      document.body.style.overflowY = "hidden";
                      handleModal("Más filtros");
                    }}
                    className="cursor-pointer relative flex justify-start items-center"
                  >
                    <p className="text-sm font-medium text-primary hidden xl:block">
                      Filtros
                    </p>{" "}
                    <p className="text-sm font-medium text-primary block xl:hidden">
                      Filtrar
                    </p>
                    <IconFilter className="ml-2" color="#2FB672" />
                  </div>
                  
                </div>
                <div className="h-8 bg-gray500 w-[0.5px] mx-3 hidden lg:block" />
                <div className="relative pl-4 lg:pl-0 md:mt-0 md:pt-0 text-center flex justify-start items-center lg:px-4">
                 {
                  ShareButton()
                 }

              <Snackbar
                      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                      open={openMessage}
                      autoHideDuration={4000}
                      onClose={() => setOpenMessage(false)}
                      message={(
                        <p className="text-center">URL copiada para compartir</p>
                      )}
                    />
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
      <div className="bg-[#F5F5F5]">
        <div className="container mx-auto px-6 xl:px-0">
          <div className="filter-container">
            {filters?.is_new !== null && (
              <span className="px-4 py-1 rounded-sm text-[#424242] bg-[#E0E0E0] font-medium text-xs lg:text-sm flex align-center items-center justify-between w-max cursor-pointer active:bg-gray-300 transition duration-300 ease option-filter mb-3">
                {filters?.is_new ? "Nuevo" : "Usado"}
                <button
                  className="bg-transparent hover focus:outline-none"
                  onClick={() => removeFilter("is_new")}
                >
                  <IconX color="#424242" width={20} className="ml-1" />
                </button>
              </span>
            )}

            {filters?.is_furnished !== null && (
              <span className="px-4 py-1 rounded-sm text-[#424242] bg-[#E0E0E0] font-medium text-xs lg:text-sm flex align-center items-center justify-between w-max cursor-pointer active:bg-gray-300 transition duration-300 ease option-filter mb-3">
                {filters?.is_furnished ? "Amueblado" : "No amueblado"}
                <button
                  className="bg-transparent hover focus:outline-none"
                  onClick={() => removeFilter("is_furnished")}
                >
                  <IconX color="#424242" width={20} className="ml-1" />
                </button>
              </span>
            )}

            {filters?.has_white_goods !== null && (
              <span className="px-4 py-1 rounded-sm text-[#424242] bg-[#E0E0E0] font-medium text-xs lg:text-sm flex align-center items-center justify-between w-max cursor-pointer active:bg-gray-300 transition duration-300 ease option-filter mb-3">
                {filters?.has_white_goods ? "Linea blanca" : "Sin linea blanca"}
                <button
                  className="bg-transparent hover focus:outline-none"
                  onClick={() => removeFilter("has_white_goods")}
                >
                  <IconX color="#424242" width={20} className="ml-1" />
                </button>
              </span>
            )}

            {filters?.min_price > 0 && (
              <span className="px-4 py-1 rounded-sm text-[#424242] bg-[#E0E0E0] font-medium text-xs lg:text-sm flex align-center items-center justify-between w-max cursor-pointer active:bg-gray-300 transition duration-300 ease option-filter mb-3">
                Mínimo: ${filters?.min_price?.toLocaleString()}
                <button
                  className="bg-transparent hover focus:outline-none"
                  onClick={() => removeFilter("min_price")}
                >
                  <IconX color="#424242" width={20} className="ml-1" />
                </button>
              </span>
            )}

            {filters?.max_price > 0 && (
              <span className="px-4 py-1 rounded-sm text-[#424242] bg-[#E0E0E0] font-medium text-xs lg:text-sm flex align-center items-center justify-between w-max cursor-pointer active:bg-gray-300 transition duration-300 ease option-filter mb-3">
                Máximo: ${filters?.max_price?.toLocaleString()}
                <button
                  className="bg-transparent hover focus:outline-none"
                  onClick={() => removeFilter("max_price")}
                >
                  <IconX color="#424242" width={20} className="ml-1" />
                </button>
              </span>
            )}

            {filters?.type && (
              <span className="px-4 py-1 rounded-sm text-[#424242] bg-[#E0E0E0] font-medium text-xs lg:text-sm flex align-center items-center justify-between w-max cursor-pointer active:bg-gray-300 transition duration-300 ease option-filter mb-3">
                {showPropertyType(filters?.type)}
                <button
                  className="bg-transparent hover focus:outline-none"
                  onClick={() => removeFilter("type")}
                >
                  <IconX color="#424242" width={20} className="ml-1" />
                </button>
              </span>
            )}

            {filters?.rooms > 0 && (
              <span className="px-4 py-1 rounded-sm text-[#424242] bg-[#E0E0E0] font-medium text-xs lg:text-sm flex align-center items-center justify-between w-max cursor-pointer active:bg-gray-300 transition duration-300 ease option-filter mb-3">
                {filters?.rooms} Habitaciones
                <button
                  className="bg-transparent hover focus:outline-none"
                  onClick={() => removeFilter("rooms")}
                >
                  <IconX color="#424242" width={20} className="ml-1" />
                </button>
              </span>
            )}

            {filters?.bathrooms > 0 && (
              <span className="px-4 py-1 rounded-sm text-[#424242] bg-[#E0E0E0] font-medium text-xs lg:text-sm flex align-center items-center justify-between w-max cursor-pointer active:bg-gray-300 transition duration-300 ease option-filter mb-3">
                {filters?.rooms} Baños
                <button
                  className="bg-transparent hover focus:outline-none"
                  onClick={() => removeFilter("bathrooms")}
                >
                  <IconX color="#424242" width={20} className="ml-1" />
                </button>
              </span>
            )}

            {filters?.parking_spots > 0 && (
              <span className="px-4 py-1 rounded-sm text-[#424242] bg-[#E0E0E0] font-medium text-xs lg:text-sm flex align-center items-center justify-between w-max cursor-pointer active:bg-gray-300 transition duration-300 ease option-filter mb-3">
                {filters?.parking_spots} Parqueos
                <button
                  className="bg-transparent hover focus:outline-none"
                  onClick={() => removeFilter("parking_spots")}
                >
                  <IconX color="#424242" width={20} className="ml-1" />
                </button>
              </span>
            )}
          </div>
        </div>
      </div>
    </>
    }
    </>
    
  );
};

export default FilterBar;
